import React, { useCallback } from "react";
import useDialog from "hooks/useDialog";
import OverlayModal from "components/dialogs/OverlayModal";
import MoveFile from "components/pageElements/MoveFile";
import IconButton from "components/buttons/IconButton";

const MoveFileButton = ({
	publicUrl, btnRef, tabIndex, name, onComplete
}) => {
	const { presentModal, dismissModal } = useDialog();

	const moveComplete = useCallback((newFolderId) => {
		dismissModal();
		onComplete?.({ type: "MOVE", folderId: newFolderId });
	}, [dismissModal, onComplete]);

	const moveFile = useCallback(() => {
		presentModal(
			<OverlayModal
				rightButtons={[<button type="button" onClick={dismissModal}>Cancel</button>]}
				title="Choose Destination"
				size="large"
			>
				<MoveFile currentUrl={publicUrl} name={name} onComplete={moveComplete} />
			</OverlayModal>
		);
	}, [presentModal, dismissModal, publicUrl, name, moveComplete]);

	return (
		<IconButton
			onClick={moveFile}
			btnRef={btnRef}
			tabIndex={tabIndex}
			icon="ant-design:file-done-outlined"
			label="Move File"
		/>
	);
};

export default MoveFileButton;
