import React, { useState, useCallback, useEffect } from "react";
import DropFileArea from "components/pageElements/DropFileArea";
import FileItem from "components/treeview/FileItem";
import useAuth from "hooks/useAuth";
import axios from "axios";
import useFolderTree from "hooks/useFolderTree";
import { webUrl } from "common/app-settings";

const Uploads = ({ basePath }) => {
	const { user, customHeaders } = useAuth();
	const { fetchFolder } = useFolderTree();
	const [uploadsList, setUploadsList] = useState(null);
	const isAdmin = user.role === "admin";

	const updateView = useCallback((info) => {
		fetchUploadsFolder(true);
		if (info?.type === "MOVE") {
			fetchFolder(info.folderId, true);
		}
	}, [fetchFolder, fetchUploadsFolder]);

	const fetchUploadsFolder = useCallback((reload = false) => {
		const path = `${basePath}/uploads/`;
		if (user && (!uploadsList || reload)) {
			axios.get(`${webUrl}${path}`, { headers: customHeaders })
				.then(({ data: fileInfo }) => {
					const listing = fileInfo.map((item, index) => (
						<FileItem
							fileItem={item}
							listIndex={index}
							filePath={`${path}${item.name}/`}
							disabled={!isAdmin}
							showMove={isAdmin}
							showDelete={isAdmin}
							updateList={updateView}
						/>
					)).filter((f) => !!f);

					if (listing.length > 0) {
						setUploadsList(listing);
					}
					else {
						setUploadsList(null);
					}
				})
				.catch((err) => {
					console.error("Could not get uploads folder info", err);
					setUploadsList([]);
				});
		}
	}, [basePath, user, uploadsList, customHeaders, isAdmin, updateView]);

	useEffect(() => {
		fetchUploadsFolder();
	}, [fetchUploadsFolder]);

	return (
		<>
			<p>{uploadsList}</p>
			<p>
				<DropFileArea
					basePath={basePath}
					userEmail={user?.email}
					fetchUploadsFolder={fetchUploadsFolder}
				/>
			</p>
		</>
	);
};

export default Uploads;
