import React, { useState } from "react";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import IconButton from "components/buttons/IconButton";
import axios from "axios";
import useAuth from "hooks/useAuth";

const DownloadButton = ({
	publicUrl, btnRef, mimeType, tabIndex
}) => {
	const { presentDialog } = useDialog();
	const { customHeaders } = useAuth();
	const [downloading, setDownloading] = useState(false);

	const fileDownload = (ev) => {
		ev.stopPropagation();
		setDownloading(true);
		const fileRequestHeaders = {
			...customHeaders,
			Accept: mimeType,
		};

		axios.get(publicUrl, { headers: fileRequestHeaders, responseType: "blob" }).then(({ data: file }) => {
			const url = URL.createObjectURL(file);
			const filename = publicUrl.split("/").pop();
			const anchor = document.createElement("A");
			anchor.setAttribute("href", url);
			anchor.setAttribute("download", filename);
			anchor.click();
			URL.revokeObjectURL(url);
		}).catch((e) => {
			presentDialog(infoDialog("Error", `Could not download the file ${e.message}`));
		}).finally(() => setDownloading(false));
	};

	const icon = downloading
		? "ant-design:hourglass-outlined"
		: "ant-design:cloud-download-outlined";

	return (
		<IconButton
			onClick={fileDownload}
			btnRef={btnRef}
			disabled={downloading}
			tabIndex={tabIndex}
			icon={icon}
			label="Download"
		/>
	);
};

export default DownloadButton;
