import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import useFolderTree from "hooks/useFolderTree";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import FolderItem from "components/treeview/FolderItem";
import useApi, { API_PUT } from "hooks/useApi";
import TextInput from "components/inputs/TextInput";

const MoveFileStyles = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	margin: 2rem;

	.chooseFolder {
		width: 100%;
	}
`;

const MoveFile = ({ currentUrl, onComplete, name }) => {
	const { folderTree } = useFolderTree();
	const api = useApi();
	const { presentDialog, dismissModal } = useDialog();
	const [treeView, setTreeView] = useState(null);
	const [fileName, setFileName] = useState(name);

	const selectFolder = useCallback((ev, folderItem) => {
		ev.stopPropagation();
		const moveData = {
			file: currentUrl,
			newPath: `${folderItem.nodePath}/${fileName}`
		};

		api({
			method: API_PUT,
			path: "/files",
			data: moveData
		}).then(() => {
			dismissModal();
			onComplete?.(folderItem.id);
		}).catch((e) => {
			presentDialog(infoDialog("Error", `Could not move the file ${e}`));
		});
	}, [currentUrl, fileName, api, dismissModal, onComplete, presentDialog]);

	useEffect(() => {
		let ind = 0;
		const buildTree = (treeNode) => (
			treeNode
				? Object.values(treeNode).map((node) => {
					ind += 1;
					const {
						id, type, children: nodeChildren, nodePath, expanded, depth
					} = node;

					if (type === "directory") {
						if (nodeChildren && Object.keys(node.children).length > 0 && expanded) {
							return (
								<FolderItem
									id={id}
									folderItem={node}
									basePath={nodePath}
									key={id}
									expanded
									tabIndex={ind}
									chooseFolder={selectFolder}
									depth={depth}
								>
									{ buildTree(nodeChildren) }
								</FolderItem>
							);
						}
						return (
							<FolderItem
								id={id}
								folderItem={node}
								basePath={nodePath}
								key={id}
								expanded={expanded}
								tabIndex={ind}
								chooseFolder={selectFolder}
								depth={depth}
							/>
						);
					}
					return null;
				})
				: null);

		const root = folderTree ? Object.values(folderTree) : null;
		if (root && Object.keys(root).length > 0) {
			setTreeView(buildTree(root));
		}
		else {
			setTreeView(
				<div>There are no documents for this project.</div>
			);
		}
	}, [folderTree, selectFolder]);

	const nameChanged = useCallback(({ value }) => {
		setFileName(value);
	}, []);

	return (
		<MoveFileStyles>
			<div className="folderName">
				<TextInput value={fileName} className="fluid" name="fileName" onChange={nameChanged} label="File Name" />
			</div>
			<div className="chooseFolder">
				{treeView}
			</div>
		</MoveFileStyles>
	);
};

export default MoveFile;
