import React from "react";
import LinkItem from "components/nav/LinkItem";

const ProjectLinks = ({ projectLinks }) => (
	<>
		<div className="projectLinks">
			{ projectLinks?.filter((f) => !!f).map((link) => {
				const { href, name } = link;
				return (
					<LinkItem href={href} name={name} key={name} />
				);
			})}
		</div>
	</>
);

export default ProjectLinks;
