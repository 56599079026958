import React from "react";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import IconButton from "components/buttons/IconButton";
import useApi, { API_DELETE } from "hooks/useApi";

const DeleteFileButton = ({
	publicUrl, btnRef, tabIndex, name, onComplete
}) => {
	const { presentDialog } = useDialog();
	const api = useApi();

	const deleteFile = () => {
		presentDialog(infoDialog("Delete File?", `Are you sure you want to delete ${name}?`, () => {
			api({
				method: API_DELETE,
				path: "/files",
				data: { path: publicUrl }
			}).then(() => {
				onComplete?.();
			}).catch((e) => {
				presentDialog(infoDialog("Error", `Could not delete file: ${e}`));
			});
		}));
	};

	return (
		<IconButton
			onClick={deleteFile}
			btnRef={btnRef}
			tabIndex={tabIndex}
			icon="ant-design:delete-outlined"
			label="Delete File"
		/>
	);
};

export default DeleteFileButton;
