import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledComponent = styled.div`
	.AppBar {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		height: 75px;
		min-height: 75px;
		padding-bottom: 0;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 0px;
		background-color: var(--white);
		border-bottom: 0.5px solid var(--lines-color);
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		position: relative;
		flex-direction: row;

		.left,
		.right,
		.middle {
			flex: 1;
		}

		.middle {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			overflow: hidden;
			min-width: 0;

			.button {
				margin: 0;
			}
		}

		.right {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex: 1;

			.button {
				margin-left: 10px;
			}

			.button:last-of-type {
				margin-left: 0;
			}
		}

		.left {
			display: flex;
			justify-content: flex-start;
			align-items: center;

			// One has to come first...
			/* stylelint-disable-next-line no-descending-specificity */
			.button {
				margin-right: 10px;
			}

			.button:last-of-type {
				margin-right: 0;
			}
		}

		// iphone landscape only
		@media only screen and (max-device-width: 480px) and (orientation: landscape) {
			height: 50px;
			align-items: center;

			.left,
			.right,
			.middle {
				margin-bottom: 0;
			}
		}

		&.overlay {
			padding-top: 0; // overlay doesn't handle notch
			align-items: center;
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
			height: 75px;
		}
	}

	.absolute {
		position: absolute;
	}

	.headline {
		font-weight: 500;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.roundedTopCorners {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
`;

const AppBar = (props) => {
	const {
		title, leftButtons, rightButtons, overlayStyle, absolute, roundTopCorners
	} = props;

	let lbIndex = 0;
	let rbIndex = 0;

	const appBarClass = [
		"AppBar",
		(overlayStyle ? "overlay" : null),
		(roundTopCorners ? "roundedTopCorners" : null),
		(absolute ? "absolute" : null)
	].filter((item) => !!item);

	return (
		<StyledComponent>
			<div className={appBarClass.join(" ")}>
				<div className="left">
					{
						leftButtons && leftButtons.map((child) => {
							lbIndex += 1;
							return React.cloneElement(child, { key: `leftButton${lbIndex}` });
						})
					}
				</div>
				<div className={["middle", "headline"].join(" ")}>
					{title}
				</div>
				<div className="right">
					{
						rightButtons && rightButtons.map((child) => {
							rbIndex += 1;
							return React.cloneElement(child, { key: `rightButton${rbIndex}` });
						})
					}
				</div>
			</div>
		</StyledComponent>
	);
};

AppBar.defaultProps = {
	leftButtons: null,
	rightButtons: null,
	overlayStyle: false,
	absolute: false
};

AppBar.propTypes = {
	leftButtons: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.object
	]),
	title: PropTypes.node.isRequired,
	rightButtons: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.object
	]),
	overlayStyle: PropTypes.bool,
	absolute: PropTypes.bool
};

export default AppBar;
