import React, { useCallback } from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import { convertToShortDate } from "common/includes";
import useFolderTree from "hooks/useFolderTree";

const StyledComponent = styled.div`
display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.5rem 0;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);
	outline: none;

	.iconAndName, .options, .size, .date {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
	}

	.options {
		flex-shrink: 1;
		min-width: 20%;
		padding-left: 0.5rem;
		text-align: right;
	}

	.iconAndName {
		padding-left: ${(props) => (props.depth ? `${(props.depth - 1) * 1.5}rem` : "0")};
		min-width: 45%;
	}

	.fileName {
		color: var(--headline-dark);
		font-weight: bold;
		margin-left: 0.5rem;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.size {
		flex-shrink: 0;
		min-width: 15%;
		padding-left: 0.5rem;
	}

	.date {
		min-width: 20%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 0.5rem;
		padding-right: 1rem;
		font-size: 0.75rem;
		text-align: right;
	}

	button {
		background: none;
		border: none;
		border-radius: 100%;
		margin: 0;
		padding: 0.15rem;
	}

	button:hover {
		background: var(--accent-color-light);
	}

	.ellipsis {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 750px) {
		.iconAndName {
			min-width: 50%;
		}
		.size {
			display: none;
		}
		.date {
			min-width: 25%;
			font-size: 0.7rem;
		}
		.options {
			min-width: 25%;
		}
	}
`;

const StyledChildComponent = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const FolderItem = ({
	folderItem, id, children, expanded, tabIndex, depth, chooseFolder
}) => {
	const { name, mtime } = folderItem;
	const { fetchFolder } = useFolderTree();

	const folderClicked = useCallback((ev) => {
		ev.stopPropagation();
		fetchFolder(id);
	}, [fetchFolder, id]);

	const folderKeyPress = useCallback((ev) => {
		if (ev.key === "Enter") {
			ev.stopPropagation();
			fetchFolder(id);
		}
	}, [fetchFolder, id]);

	return (
		<>
			<StyledComponent onKeyPress={folderKeyPress} onClick={folderClicked} role="button" tabIndex={tabIndex} depth={depth}>
				<div className="iconAndName">
					<Icon icon={`fa-solid:angle-${expanded ? "down" : "right"}`} width="16" height="16" style={{ marginRight: "0.25rem" }} />
					<Icon icon={expanded ? "fa-solid:folder-open" : "fa-solid:folder"} width="20" height="20" color="var(--accent-color)" />
					<span className="fileName">{name}</span>
				</div>
				{ chooseFolder
					? (
						<div className="options">
							<button type="button" onClick={(ev) => chooseFolder(ev, folderItem)}>
								<Icon icon="fa-solid:folder-plus" width="16" height="16" />
							</button>
						</div>
					)
					: (
						<>
							<div className="size">&nbsp;</div>
							<div className="date"><div className="ellipsis">{convertToShortDate(mtime)}</div></div>
							<div className="options">&nbsp;</div>
						</>
					) }
			</StyledComponent>
			{ children && <StyledChildComponent>{children}</StyledChildComponent> }
		</>

	);
};

export default FolderItem;
