import { Icon } from "@iconify/react";
import React from "react";

const IconButton = ({
	onClick,
	btnRef,
	style,
	tabIndex,
	icon,
	label,
	size
}) => (
	<button
		type="button"
		className="iconButton"
		onClick={onClick}
		ref={btnRef}
		style={style}
		tabIndex={tabIndex}
		aria-label={label}
	>
		<Icon icon={icon} width={size ?? "18"} height={size ?? "18"} />
	</button>
);

export default IconButton;
