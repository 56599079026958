import React, { useRef } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import fileTypes from "common/knownFileTypes";
import { convertToShortDate } from "common/includes";
import PreviewButton from "components/buttons/PreviewButton";
import DownloadButton from "components/buttons/DownloadButton";
import MoveFileButton from "components/buttons/MoveFileButton";
import DeleteFileButton from "components/buttons/DeleteFileButton";
import { webUrl } from "common/app-settings";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	max-width: 100%;
	padding: 0.5rem 0;
	align-items: center;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);

	.iconAndName, .options, .size, .date {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	.options {
		flex-shrink: 1;
		min-width: 20%;
		padding-left: 0.5rem;
		text-align: right;
	}

	.iconAndName {
		padding-left: ${(props) => (props.depth ? `${(props.depth - 1) * 1.5}rem` : "0")};
		min-width: 50%;
	}

	.fileName {
		color: var(--headline-dark);
		font-weight: bold;
		margin-left: 0.5rem;
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.size {
		min-width: 10%;
		text-align: right;
		padding-left: 0.5rem;
		font-size: 0.75rem;
	}

	.date {
		min-width: 20%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-left: 0.5rem;
		padding-right: 1rem;
		font-size: 0.75rem;
		text-align: right;
	}

	.ellipsis {
		white-space: nowrap;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 750px) {
		.iconAndName {
			min-width: 50%;
		}
		.size {
			display: none;
		}
		.date {
			min-width: 25%;
			font-size: 0.7rem;
		}
		.options {
			min-width: 25%;
		}
	}
`;

const FileItem = ({
	fileItem, filePath, disabled = false, id, tabIndex, showDelete, showMove, updateList, depth
}) => {
	const { name, mtime, size } = fileItem;
	const info = name.split(".");
	const extension = info[info.length - 1];
	const previewButtonRef = useRef();
	const downloadButtonRef = useRef();

	const fileTypeInfo = fileTypes[extension] ?? {
		icon: "fa-regular:file", mime: "application/octet-stream", preview: false, description: `${extension} File`
	};

	const fileSize = size > 1024
		? `${Math.round((size / 1024 / 1024) * 100) / 100} MB`
		: `${Math.round((size / 1024) * 100) / 100} KB`;

	if (name === "folderInfo.json" || name === "folderLinks.json") {
		return null;
	}

	const {
		icon, mime, description, preview
	} = fileTypeInfo;

	const publicURL = `${webUrl}${filePath}`;

	return (
		<StyledComponent title={description} dataId={id} depth={depth}>
			<div className="iconAndName">
				<Icon icon={icon} width="18" height="18" />
				<span className="fileName">{name}</span>
			</div>
			<div className="size"><div className="ellipsis">{fileSize}</div></div>
			<div className="date"><div className="ellipsis">{convertToShortDate(mtime)}</div></div>
			{ !disabled
				? (
					<div className="options">
						{ preview
							? (
								<PreviewButton
									publicUrl={publicURL}
									extension={extension}
									mimeType={mime}
									name={name}
									btnRef={previewButtonRef}
									tabIndex={tabIndex}
								/>
							)
							: <div /> }
						<DownloadButton
							publicUrl={publicURL}
							btnRef={downloadButtonRef}
							mimeType={mime}
							tabIndex={tabIndex}
						/>
						{
							showDelete
								? (
									<DeleteFileButton
										publicUrl={publicURL}
										extension={extension}
										mimeType={mime}
										name={name}
										tabIndex={tabIndex}
										onComplete={updateList}
									/>
								)
								: null
						}
						{
							showMove
								? (
									<MoveFileButton
										publicUrl={publicURL}
										extension={extension}
										mimeType={mime}
										name={name}
										tabIndex={tabIndex}
										onComplete={updateList}
									/>
								)
								: null
						}
					</div>
				) : null }
		</StyledComponent>
	);
};

export default FileItem;
