import React from "react";
import { Router } from "@gatsbyjs/reach-router";
import ClientIndex from "components/client/ClientIndex";

const App = () => (
	<Router
		basepath="/client/*"
		style={{
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: "100%"
		}}
	>
		<ClientIndex path="/" />
	</Router>
);

export default App;
