import React, { useState } from "react";

const TextInput = ({
	value, onChange, name, label
}) => {
	const [text, setText] = useState(value);

	const changeText = (ev) => {
		const { value: newValue } = ev.target;
		setText(newValue);
		onChange({ name, value: newValue });
	};

	return label
		? (
			<label htmlFor={name}>
				{label}
				<input type="text" className="fluid" name={name} value={text} onChange={changeText} />
			</label>
		)
		: <input type="text" className="fluid" name={name} value={text} onChange={changeText} />;
};

export default TextInput;
