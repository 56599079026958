import React, {
	useCallback, useEffect, useRef, useState
} from "react";
import FileItem from "components/treeview/FileItem";
import FolderItem from "components/treeview/FolderItem";
import useAuth from "hooks/useAuth";
import useFolderTree from "hooks/useFolderTree";

const Documents = ({ basePath }) => {
	const initialLoadRef = useRef(true);
	const [treeView, setTreeView] = useState(null);
	const {
		folderTree, fetchFolder, initialiseTree, resetTree
	} = useFolderTree();
	const { user } = useAuth();

	const loadFromRoot = useCallback(() => {
		fetchFolder(Object.keys(folderTree)[0], true);
	}, [fetchFolder, folderTree]);

	useEffect(() => {
		let ind = 0;
		const isAdmin = user.role === "admin";
		const buildTree = (treeNode) => (
			treeNode
				? Object.values(treeNode).map((node) => {
					ind += 1;
					const {
						id, type, children: nodeChildren, nodePath, expanded, depth
					} = node;

					if (type === "directory") {
						if (nodeChildren && Object.keys(node.children).length > 0 && expanded) {
							return (
								<FolderItem
									id={id}
									folderItem={node}
									basePath={nodePath}
									key={id}
									expanded
									tabIndex={ind}
									depth={depth}
								>
									{ buildTree(nodeChildren) }
								</FolderItem>
							);
						}
						return (
							<FolderItem
								id={id}
								folderItem={node}
								basePath={nodePath}
								key={id}
								expanded={expanded}
								tabIndex={ind}
								depth={depth}
							/>
						);
					}
					return (
						<FileItem
							id={id}
							fileItem={node}
							filePath={nodePath}
							key={id}
							tabIndex={ind}
							showDelete={isAdmin}
							updateList={loadFromRoot}
							depth={depth}
						/>
					);
				})
				: null);

		const root = folderTree ? Object.values(folderTree) : null;
		if (root?.[0]?.children && Object.keys(root[0].children).length > 0) {
			setTreeView(buildTree(root[0].children));
		}
		else {
			setTreeView(
				<div>There are no documents for this project.</div>
			);
		}
	}, [folderTree, loadFromRoot, user?.role]);

	useEffect(() => {
		initialiseTree(basePath);

		return () => {
			initialLoadRef.current = true;
			resetTree();
		};
	}, [initialiseTree, basePath, resetTree]);

	useEffect(() => {
		if (folderTree && initialLoadRef.current) {
			loadFromRoot();
			initialLoadRef.current = false;
		}
	}, [folderTree, loadFromRoot]);

	return treeView ?? null;
};

export default Documents;
