import React, { useRef, useState } from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import { FileDrop } from "react-file-drop";
import { infoDialog } from "components/dialogs/AlertDialog";
import useDialog from "hooks/useDialog";
import useApi, { API_POST } from "hooks/useApi";

const StyledComponent = styled.div`
	.file-drop > .file-drop-target {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 300px;
		flex: 1;
		padding: 1rem;
		align-items: center;
		justify-content: center;
		font-size: small;
		line-height: initial;
		text-align: center;
		border: 1px solid var(--accent-color);
		border-radius: 20px;
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-frame {
		border: 2px solid var(--accent-color);
	}

	.file-drop > .file-drop-target.file-drop-dragging-over-target {
		background-color: var(--accent-color-light);
	}

	.hidden {
		display: none;
	}
`;

const DropFileArea = ({
	basePath, userEmail, fetchUploadsFolder, ...rest
}) => {
	const fileInputRef = useRef();
	const progressRef = useRef();
	const api = useApi();
	const [uploadStatus, setUploadStatus] = useState();
	const { presentDialog } = useDialog();

	const handleFileUpload = (files) => {
		const fd = new FormData();
		files.forEach((f, ind) => {
			fd.append(`file${ind}`, f);
		});
		fd.append("basePath", basePath);
		fd.append("user", userEmail);
		const sp = basePath.split("/");
		fd.append("client", sp[2]);
		fd.append("project", sp[3]);
		const head = {
			"Content-Type": "multipart/form-data"
		};

		api({
			method: API_POST,
			path: "/files",
			data: fd,
			headers: head,
			options: {
				onUploadProgress: (progressEvent) => {
					const { lengthComputable, loaded, total } = progressEvent;
					if (lengthComputable) {
						if (loaded === total) {
							setUploadStatus("(processing...)");
						}
						else {
							const progress = Math.round((loaded / total) * 100);
							progressRef.current.value = progress;
							setUploadStatus(`${progress}%`);
						}
					}
					else {
						setUploadStatus("(processing...)");
					}
				}
			}
		}).then((fileData) => {
			if (fileData.find((t) => t.status === "rejected")) {
				const messages = fileData.map(({ status, reason }) => {
					if (status === "rejected") {
						return reason.fileName;
					}
					return null;
				}).filter((t) => !!t);
				presentDialog(
					infoDialog(
						"Error",
						`Some files failed to upload. The following files failed: ${messages.join(", ")}`
					)
				);
				setUploadStatus("Error!");
			}
			else {
				setUploadStatus("Success!");
				fetchUploadsFolder(true);
			}
			setTimeout(() => {
				setUploadStatus(undefined);
			}, 3000);
		});
	};

	const dropFiles = (files, ev) => {
		ev.preventDefault();
		if (!uploadStatus && files?.length > 0) {
			setUploadStatus("(preparing...)");
			handleFileUpload(files);
		}
	};

	const onFileInputChange = (ev) => {
		ev.preventDefault();
		const { files } = ev.target;
		if (files?.length > 0) {
			setUploadStatus("(preparing...)");
			handleFileUpload(files);
		}
	};

	const onTargetClick = () => {
		if (!uploadStatus) {
			fileInputRef.current?.click();
		}
	};

	return (
		<StyledComponent {...rest}>
			<FileDrop
				onDrop={dropFiles}
				onTargetClick={onTargetClick}
			>
				{ uploadStatus
					? (
						<>
							<progress ref={progressRef} value="0" min="0" max="100" />
							{uploadStatus}
						</>
					)
					: (
						<>
							<Icon icon="ant-design:cloud-upload-outlined" width="32" height="32" />
							Drop files or click here to upload files to Mothership.
						</>
					)}
			</FileDrop>
			<input type="file" className="hidden" ref={fileInputRef} onChange={onFileInputChange} />
		</StyledComponent>
	);
};

export default DropFileArea;
