import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 2rem;
	justify-content: flex-end;
	align-items: flex-start;

	&:hover {
		background-color: ${(props) => (props.selected ? "var(--accent-color)" : "var(--accent-color-light)")};
	}

	height: 300px;
	@media (max-width: 768px) {
		height: initial;
	}
	background-color: ${(props) => (props.selected ? "var(--accent-color)" : "transparent")};
	border: ${(props) => (props.selected ? "2px solid var(--accent-color)" : "2px solid var(--accent-color)")};
	color: ${(props) => (props.selected ? "black" : "var(--accent-color)")};
	font-weight: 700;
	border-radius: 0.5rem;
	cursor: pointer;

	h5 {
		margin:0;
	}
`;

const ClientProjectItem = ({ projectTitle, ...rest }) => (
	<StyledComponent {...rest}>
		{projectTitle}
	</StyledComponent>
);

export default ClientProjectItem;
