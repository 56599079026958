import React from "react";
import useDialog from "hooks/useDialog";
import OverlayModal from "components/dialogs/OverlayModal";
import { infoDialog } from "components/dialogs/AlertDialog";
import IconButton from "components/buttons/IconButton";
import axios from "axios";
import useAuth from "hooks/useAuth";

const PreviewButton = ({
	publicUrl, name, btnRef, mimeType, tabIndex
}) => {
	const { presentModal, dismissModal, presentDialog } = useDialog();
	const { customHeaders } = useAuth();
	const previewFile = (ev) => {
		ev.stopPropagation();
		const fileRequestHeaders = {
			...customHeaders,
			Accept: mimeType,
		};

		const closePreview = (url) => {
			URL.revokeObjectURL(url);
			dismissModal();
		};

		axios.get(publicUrl, { headers: fileRequestHeaders, responseType: "blob" }).then(({ data: file }) => {
			const url = URL.createObjectURL(file);

			presentModal(
				<OverlayModal
					noMargin
					size="fullscreen"
					extraClass={mimeType.replace("/", "-")}
					title={name}
					rightButtons={[<button onClick={() => closePreview(url)} type="button">Close</button>]}
				>
					<iframe width="100%" height="100%" src={url} title="File iFrame" />
				</OverlayModal>
			);
		}).catch((e) => {
			presentDialog(infoDialog("Error", `Could not preview the file ${e.message}`));
		});
	};

	return (
		<IconButton
			onClick={previewFile}
			btnRef={btnRef}
			tabIndex={tabIndex}
			icon="ant-design:eye-outlined"
		/>
	);
};

export default PreviewButton;
