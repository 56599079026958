import React from "react";
import styled from "styled-components";

const StyledComponent = styled.div`
    display: grid; 
    grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    grid-column-gap: 1rem;
    grid-row-gap: 1rem; 

`;

const Grid = ({ children, ...rest }) => (
	<StyledComponent {...rest}>
		{children}
	</StyledComponent>
);

export default Grid;
