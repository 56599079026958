import React, { useEffect, useState, useMemo } from "react";
import ClientProjectItem from "components/client/ClientProjectItem";
import useAuth from "hooks/useAuth";
import Page from "components/layout/Page";
import Columns from "components/layout/Columns";
import Documents from "components/client/Documents";
import Uploads from "components/client/Uploads";
import ProjectLinks from "components/client/ProjectLinks";
import Flex from "components/layout/Flex";
import Grid from "components/layout/Grid";
import Layout from "components/layout/Layout";
import useApi from "hooks/useApi";

const ClientIndex = () => {
	const { user, isLoggedIn } = useAuth();
	const api = useApi();
	const { firstName } = user ?? {};
	const [projectList, setProjectList] = useState();
	const [selectedProject, setSelectedProject] = useState();

	const changeProject = (id) => {
		setSelectedProject(id);
		localStorage.setItem("selectedProject", id);
	};

	useEffect(() => {
		const getUserProjects = () => {
			api({ path: "/projects" }).then((data) => {
				setProjectList(data);
			});
		};

		if (isLoggedIn && !projectList) {
			getUserProjects();
		}
	}, [api, isLoggedIn, projectList]);

	useEffect(() => {
		if (projectList?.length >= 0) {
			const projId = localStorage.getItem("selectedProject");
			if (projId && projId !== selectedProject?.id) {
				const proj = projectList?.find((p) => p.id === projId);
				if (proj) {
					setSelectedProject(proj);
				}
				else {
					setSelectedProject(projectList?.[0]);
				}
			}
			else if (!selectedProject) {
				setSelectedProject(projectList?.[0]);
			}
		}
	}, [projectList, selectedProject]);

	const { id: projectPath } = selectedProject ?? {};
	const basePath = useMemo(() => (projectPath
		? `client/${projectPath}`
		: undefined
	), [projectPath]);

	return (
		selectedProject && basePath
			? (
				<Layout>
					<Page
						minHeight="50vh"
						middle={(
							<Flex>
								<h1>
									{`Hi ${firstName},`}
									<br />
									Welcome to your client area.
									<br />
								</h1>
								<p>A place where we can share project assets and progress.</p>
							</Flex>
						)}
					/>
					<Page
						minHeight="50vh"
						top={(<h3>Select a project</h3>)}
						middle={(
							<Grid>
								{
									projectList?.map(({
										id, displayName, title, client: projClient, path
									}) => (
										<ClientProjectItem
											key={id}
											projectName={displayName}
											projectTitle={title}
											projectLink={`/client/${projClient}/${path}/documents`}
											onClick={() => changeProject(id)}
											selected={selectedProject?.id === id}
										/>
									))
								}
							</Grid>
						)}
						bottom={(
							<Flex marginTop="3rem">
								{ selectedProject ? (
									<Columns>
										<div className="column">
											<h3>Project description</h3>
											<div dangerouslySetInnerHTML={{ __html: selectedProject.description }} />
										</div>
										<div className="column">
											<h3>Relevent links</h3>
											<ProjectLinks projectLinks={selectedProject.links} />
										</div>
									</Columns>
								) : null }
							</Flex>
						)}
					/>
					<Page
						minHeight="50vh"
						top={(
							<>
								<h3>Files</h3>
								{ projectPath ? (
									<Documents basePath={basePath} />) : null }
							</>
						)}
					/>
					<Page
						minHeight="50vh"
						top={(
							<>
								<h3>Send to Mothership</h3>
								<Uploads basePath={basePath} />
							</>
						)}
					/>
				</Layout>
			)
			: null
	);
};

export default ClientIndex;
