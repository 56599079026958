import React from "react";
import { Icon } from "@iconify/react";
import styled from "styled-components";
import ExternalLink from "components/nav/ExternalLink";

const StyledComponent = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 0.5rem 0;
	align-items: center;
	font-size: small;
	border-bottom: 1px solid var(--lines-color);

	a:link {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		border-bottom: none;
	}

	.LinkNameAndUrl {
		display: flex;
		flex-direction: column;
		flex: 1;
		align-items: flex-start;
		line-height: initial;
		padding: 0 0.5rem;
	}

	.linkName {
		font-weight: bold;
	}

	.linkUrl {
		font-weight: 300;
		opacity: 0.6;
	}
`;

const LinkItem = ({ name, href, ...rest }) => (
	<StyledComponent {...rest}>
		<ExternalLink to={href}>
			<Icon icon="fa-solid:link" width="16" height="16" />
			<div className="LinkNameAndUrl">
				<span className="linkName">{name}</span>
				<span className="linkUrl">{href}</span>
			</div>
		</ExternalLink>
	</StyledComponent>
);

export default LinkItem;
