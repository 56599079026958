import React from "react";
import styled from "styled-components";
import AppBar from "./AppBar";

const StyledComponent = styled.div`
	.modalContainer {
		display: flex;
		flex-direction: row;
		flex: 1;
		width: 100%;
		height: 100%;
		background: var(--white);
		overflow: hidden;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		transition: background 1s ease;
		z-index: 1000 !important;
	}

	.modalPage {
		display: flex;
		flex-direction: column;
		position: fixed;
		justify-content: center;
		align-items: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
	}

	.modalPage.overlay {
		background: var(--modal-background);
		backdrop-filter: blur(3px);
	}

	.modalPageOverlayWrapper {
		display: flex;
		position: relative;
		flex-direction: column;
		box-shadow: var(--box-shadow);
		border-radius: 6px;

		.modalContainer {
			border-bottom-left-radius: 6px;
			border-bottom-right-radius: 6px;
		}

		.roundTop {
			border-top-left-radius: 6px;
			border-top-right-radius: 6px;
		}
	}

	.horizontalScroll {
		overflow-x: scroll !important;
	}


	.large {
		.modalPageOverlayWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: calc( 100% - 10rem);
			width: calc( 100% - 2rem);
			max-width: 1000px;
		}

		.appBarRadius {
			border-radius: 6px;
		}
		
	}

	.fullscreen {
		.modalPageOverlayWrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
			max-width: initial;
		}

		.appBarRadius {
			border-radius: 6px;
		}
		
	}


	.modalContent {
		position: relative;
		width: 100%;
		div[class^="Page_"] {
			min-height: initial;
		}
	}

	.image-png, .image-jpg, .image-jpeg, .image-gif {
		.modalContent {
			display: flex;
			position: relative;
			justify-content: center;
			align-items: center;
			padding: 1rem;
			width: 100%;
			div[class^="Page_"] {
				min-height: initial;
			}

			img {
				max-width: 100%;
				width: auto;
				height: auto;
				box-shadow: 0px 1rem 2rem rgba(0,0,0,0.2);
			}
		}
	}

	.appBarContainer {
		width: 100%;
	}
`;

const OverlayModal = (props) => {
	const {
		extraClass,
		children,
		size = "large",
		horizontalScroll,
		maxWidthContent,
		containerRef,
		hideAppBar,
		noMargin,
		contentClass,
		rightButtons,
		...rest
	} = props;

	const styleArray = [
		"modalContainer",
		(extraClass || null),
		(horizontalScroll ? "horizontalScroll" : null),
		(hideAppBar ? "roundTop" : null)
	].filter((a) => a !== null && a !== undefined);

	const contentArray = [
		"modalContent",
		(contentClass || null),
		(maxWidthContent ? "maxWidth" : null),
		(noMargin ? "noMargin" : null)
	].filter((a) => a !== null && a !== undefined);

	return (
		<StyledComponent>
			<div className={["modalPage", "overlay", size].join(" ")} id="modalPage">
				<div className="modalPageOverlayWrapper">
					{!hideAppBar
					&& (
						<div className="appBarContainer">
							<AppBar
								rightButtons={rightButtons}
								overlayStyle
								{...rest}
							/>
						</div>
					)}
					<div id="modalContainer" className={styleArray.join(" ")} ref={containerRef}>
						<div className={contentArray.join(" ")} role="presentation">
							{ children }
						</div>
					</div>

				</div>

			</div>
		</StyledComponent>
	);
};

export default OverlayModal;
