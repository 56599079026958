const fileTypes = {
	doc: {
		mime: "application/msword",
		icon: "fa-regular:file-word",
		description: "Microsoft Word Document",
		preview: false
	},
	docx: {
		mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
		icon: "fa-regular:file-word",
		description: "Microsoft Word Document",
		preview: false
	},
	xls: {
		mime: "application/vnd.ms-excel",
		icon: "fa-regular:file-excel",
		description: "Microsoft Excel Document",
		preview: false
	},
	xlsx: {
		mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		icon: "fa-regular:file-excel",
		description: "Microsoft Excel Document",
		preview: false
	},
	ppt: {
		mime: "application/vnd.ms-powerpoint",
		icon: "fa-regular:file-powerpoint",
		description: "Microsoft PowerPoint Document",
		preview: false
	},
	pptx: {
		mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
		icon: "fa-regular:file-powerpoint",
		description: "Microsoft PowerPoint Document",
		preview: false
	},
	pdf: {
		mime: "application/pdf",
		icon: "fa-regular:file-pdf",
		description: "PDF Document",
		preview: true
	},
	dcm: {
		mime: "image/dicom",
		icon: "fa-regular:file-image",
		description: "DICOM Image File",
		preview: false
	},
	png: {
		mime: "image/png",
		icon: "fa-regular:file-image",
		description: "PNG Image File",
		preview: true
	},
	gif: {
		mime: "image/gif",
		icon: "fa-regular:file-image",
		description: "GIF Image File",
		preview: true
	},
	jpg: {
		mime: "image/jpeg",
		icon: "fa-regular:file-image",
		description: "JPEG Image File",
		preview: true
	},
	jpeg: {
		mime: "image/jpeg",
		icon: "fa-regular:file-image",
		description: "JPEG Image File",
		preview: true
	},
	bmp: {
		mime: "image/bmp",
		icon: "fa-regular:file-image",
		description: "Microsoft Bitmap Image File",
		preview: true
	},
	svg: {
		mime: "image/svg+xml",
		icon: "fa-regular:file-image",
		description: "Scalable Vector Graphics Image File",
		preview: true
	},
	txt: {
		mime: "text/plain",
		icon: "fa-regular:file-alt",
		description: "Text File",
		preview: true
	},
	avi: {
		mime: "video/x-msvideo",
		icon: "fa-regular:file-video",
		description: "AVI Video File",
		preview: false
	},
	mpeg: {
		mime: "video/mpeg",
		icon: "fa-regular:file-video",
		description: "MPEG Video File",
		preview: false
	},
	mp4: {
		mime: "video/mp4",
		icon: "fa-regular:file-video",
		description: "MPEG-4 Video File",
		preview: false
	},
	mov: {
		mime: "video/quicktime",
		icon: "fa-regular:file-video",
		description: "Quicktime MOV Video File",
		preview: false
	},
	mp3: {
		mime: "audio/mpeg",
		icon: "fa-regular:file-audio",
		description: "MP3 Audio File",
		preview: false
	},
	aac: {
		mime: "audio/aac",
		icon: "fa-regular:file-audio",
		description: "iTunes AAC Audio File",
		preview: false
	},
	wav: {
		mime: "audio/wav",
		icon: "fa-regular:file-audio",
		description: "Windows Audio File",
		preview: false
	},
	flac: {
		mime: "audio/flac",
		icon: "fa-regular:file-audio",
		description: "FLAC Audio File",
		preview: false
	},
	alac: {
		mime: "video/mp4",
		icon: "fa-regular:file-audio",
		description: "Apple Lossless Audio File",
		preview: false
	},
	zip: {
		mime: "application/zip",
		icon: "fa-regular:file-archive",
		description: "ZIP Archive File",
		preview: false
	},
	rar: {
		mime: "application/vnd.rar",
		icon: "fa-regular:file-archive",
		description: "RAR Archive File",
		preview: false
	},
	gz: {
		mime: "application/gzip",
		icon: "fa-regular:file-archive",
		description: "ZIP Archive File",
		preview: false
	},
};

export default fileTypes;
